//
// Theme style
//

// Initialize
@import 'init';

// Components
@import './core/components/components';
@import 'components/components';

// Layout
@import './core/layout/base/layout';
@import 'layout/layout';

.app-content {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.app-toolbar {
  padding: 0 !important;
  padding-top: 15px !important;
}

h1.page-heading {
  margin-bottom: 0.2rem !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.btn {
  font-family: 'Poppins', sans-serif;
}

.card {
  border-radius: 0 !important;
}

.card .card-body {
  padding: 2rem !important;
}

.btn:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush):not(.btn-icon),
.btn.btn-outline {
  padding: calc(0.5rem + 1px) calc(1.5rem + 1px) !important;
}

.form-control,
.form-select {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border: 1px solid #e7eef3 !important;
}

.react-tel-input {
  border: 1px solid #e7eef3 !important;
}

.input-color {
  border: 1px dashed #d1dce4;
  border-radius: 6px;
  padding: 0.5rem;
}
.input-color input {
  box-sizing: border-box;
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  box-shadow: none;
  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;
  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;
  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;
  /* Remove excess padding and border in Firefox 4+ */
  border: 0;
  padding: 0;
  width: 100%;
  height: 4rem;
  display: block;
  cursor: pointer;
}

.add-button {
  position: absolute;
  right: 0;
  top: -4.2rem;
}

.btn {
  font-size: 1rem;
}

.form-label {
  font-size: 0.9rem;
  font-weight: 600;
  margin-bottom: 0.1rem;
}

.split-button {
  display: flex;
  flex-direction: row;
  gap: 10px;
  max-width: 30rem;
}

.split-button .btn {
  flex: 1 1 100px;
}

.align-bottom {
  align-self: flex-end;
}

table th {
  font-size: 0.9rem !important;
  font-family: 'Poppins', sans-serif;
}

table td {
  vertical-align: middle;
}

#table .toggle-status {
  border-radius: 100rem;
  font-size: 0.8rem !important;
  font-weight: 600;
  padding: 0.2rem 2rem 0.2rem 0.5rem !important;
  position: relative;
}

#table .toggle-status::after {
  content: '';
  display: block;
  position: absolute;
  width: 14px;
  height: 14px;
  right: 6px;
  top: 0;
  bottom: 0;
  margin: auto;
  background-repeat: no-repeat;
  background-size: 90%;
  background-position: 50% 50%;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI3LjAuMSwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjQgMjQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDpub25lO3N0cm9rZTojRkZGRkZGO3N0cm9rZS13aWR0aDoyO3N0cm9rZS1saW5lY2FwOnJvdW5kO3N0cm9rZS1saW5lam9pbjpyb3VuZDt9Cjwvc3R5bGU+Cjxwb2x5bGluZSBjbGFzcz0ic3QwIiBwb2ludHM9IjIzLDQgMjMsMTAgMTcsMTAgIi8+Cjxwb2x5bGluZSBjbGFzcz0ic3QwIiBwb2ludHM9IjEsMjAgMSwxNCA3LDE0ICIvPgo8cGF0aCBjbGFzcz0ic3QwIiBkPSJNMy41LDlDNS4yLDQuMywxMC4zLDEuOSwxNSwzLjVjMS4zLDAuNCwyLjQsMS4yLDMuNCwyLjFMMjMsMTAgTTEsMTRsNC42LDQuNGMzLjUsMy41LDkuMiwzLjUsMTIuNywwCgljMC45LTAuOSwxLjctMi4xLDIuMS0zLjQiLz4KPC9zdmc+Cg==);
}

#table .toggle-status.btn-light::after {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI3LjAuMSwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjQgMjQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDpub25lO3N0cm9rZTojN0U4Mjk5O3N0cm9rZS13aWR0aDoyO3N0cm9rZS1saW5lY2FwOnJvdW5kO3N0cm9rZS1saW5lam9pbjpyb3VuZDt9Cjwvc3R5bGU+Cjxwb2x5bGluZSBjbGFzcz0ic3QwIiBwb2ludHM9IjIzLDQgMjMsMTAgMTcsMTAgIi8+Cjxwb2x5bGluZSBjbGFzcz0ic3QwIiBwb2ludHM9IjEsMjAgMSwxNCA3LDE0ICIvPgo8cGF0aCBjbGFzcz0ic3QwIiBkPSJNMy41LDlDNS4yLDQuMywxMC4zLDEuOSwxNSwzLjVjMS4zLDAuNCwyLjQsMS4yLDMuNCwyLjFMMjMsMTAgTTEsMTRsNC42LDQuNGMzLjUsMy41LDkuMiwzLjUsMTIuNywwCgljMC45LTAuOSwxLjctMi4xLDIuMS0zLjQiLz4KPC9zdmc+Cg==);
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: #fdfdfd !important;
}

.table-striped > tbody > tr {
  border-bottom: 1px dashed #efefef;
}

.icon-dropdown {
  padding: 0;
  border: none;
  background: transparent;
}

.icon-dropdown::after {
  display: none;
}

.icon-dropdown i {
  font-size: 2rem;
  transition: 0.3s ease all;
}

.icon-dropdown:hover i,
.icon-dropdown.show i {
  color: #1e1e2d;
}

.table > :not(caption) > * > * {
  padding: 0.4rem 0.4rem !important;
}

.card .card-header {
  min-height: 50px;
}

.react-tel-input {
  width: 100%;
  background-color: #f5f8fa;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.5;
  font-family: Inter, Helvetica, 'sans-serif';
  border-radius: 0.475rem;
}

.react-tel-input .form-control {
  border: 0 !important;
  background-color: transparent !important;
}

.react-tel-input .flag-dropdown {
  border: 0 !important;
  background-color: transparent !important;
  border-right: 1px solid #ebebeb !important;
}

.image-input.image-input-outline .image-input-wrapper {
  background-color: #f1f1f1 !important;
  box-shadow: none;
  border: 1px solid #e3e3e3;
  border-radius: 0;
  margin-bottom: 1.5rem;
}

.image-holder {
}

.login-side {
  display: none !important;
}

.caMainLogo {
  width: 10rem;
  margin-bottom: 2rem;
}

.main-logo {
  height: 35px;
}

.short-icon {
  height: 30px;
}

.app-sidebar {
  background-color: #ffffff !important;
}

.menu-link span {
  transition: 0.3s ease all;
}

.menu-link:hover .menu-title {
  transform: translateX(7px);
}

.menu-link:hover .menu-icon {
  opacity: 1 !important;
}

.app-sidebar .menu .menu-item .menu-link.active:hover .menu-title {
  transform: translateX(0);
}

.menu-link .menu-icon {
  position: relative;
}

.menu-link .menu-icon::before {
  content: '';
  display: block;
  position: absolute;
  left: -0.3rem;
  width: 35px;
  height: 35px;
  border-radius: 100rem;
  margin: auto;
  opacity: 0;
  border: 1px solid #000;
  transition: 0.3s ease all;
}

.menu-link:hover .menu-icon::before {
  opacity: 0.1;
}

:root {
  --kt-app-header-height: 56px;
}

.app-sidebar .menu .menu-item .menu-link .menu-icon,
.app-sidebar .menu .menu-item .menu-link .menu-icon .svg-icon,
.app-sidebar .menu .menu-item .menu-link .menu-icon i {
  opacity: 0.7;
}

.app-sidebar .menu .menu-item .menu-link.active .menu-icon,
.app-sidebar .menu .menu-item .menu-link.active .menu-icon .svg-icon,
.app-sidebar .menu .menu-item .menu-link.active .menu-icon i {
  opacity: 1;
}

.app-sidebar .menu .menu-item .menu-link .menu-title {
  color: #635c66 !important;
}

.app-sidebar .app-sidebar-logo {
  border-bottom: 1px solid #f5f8fa !important;
  background-color: #ffff;
  box-shadow: 3px -10px 10px rgb(0 0 0 / 7%);
}

.app-sidebar .app-sidebar-logo .svg-icon {
  color: #fff !important;
}

.app-sidebar-toggle {
  border-radius: 100rem;
  opacity: 0.7;
}

.break-title {
  border-bottom: 1px solid #ecf0f4;
  padding-bottom: 0.5rem;
  margin: 1.5rem 0;
}

.link-primary {
  opacity: 0.7;
}

.link-primary:hover,
.link-primary:focus {
  opacity: 1;
}

.btn-outline-primary {
  opacity: 0.8;
}

.btn.btn-secondary,
.btn.btn-primary {
  transition: 0.3s ease all;
}

.btn.btn-secondary:hover,
.btn.btn-primary:hover {
  filter: saturate(0.6);
}

.css-t3ipsp-control:hover {
  border-color: var(--kt-primary) !important;
}

.pagination .custom-active .page-link {
  background-color: #49494d;
  color: #fff;
  cursor: default !important;
}
.pagination .custom-active .page-link:hover {
  color: #ffffff !important;
  background-color: #49494d !important;
}
.intro-txt {
  color: var(--kt-form-label-color);
}
.user-sub {
  flex-direction: column;
}
.user-sub small {
  font-size: 0.9rem;
  font-weight: 500;
  display: block;
  color: var(--kt-primary);
}
.user-sub span {
  font-weight: 700;
  font-size: 1rem;
  display: block;
  line-height: 1.2;
  color: #3f4254;
}
.sub-sub a {
  display: block;
  font-weight: 400;
  font-size: 1rem;
  cursor: pointer;
  color: #635c66 !important;
  transition: 0.3s ease all;
}
.sub-sub a:hover {
  transform: translateX(0.5rem);
}
.same-col {
  justify-content: space-between;
  align-items: center;
}
/*******Reset Colors******************/
:root {
  --kt-primary: #752f8b;
  --kt-primary-active: #752f8b;

  --kt-secondary: #f66b00;
  --kt-secondary-inverse: #fff;
  --kt-secondary-active: #f66b00;
}
.profile-title {
  color: var(--kt-primary);
}

.app-sidebar .menu .menu-item .menu-link.active {
  background-color: var(--kt-primary) !important;
}

.app-sidebar .menu .menu-item .menu-link .menu-icon,
.app-sidebar .menu .menu-item .menu-link .menu-icon .svg-icon,
.app-sidebar .menu .menu-item .menu-link .menu-icon i {
  color: var(--kt-primary) !important;
}

.app-sidebar .menu .menu-item .menu-link.active .menu-icon,
.app-sidebar .menu .menu-item .menu-link.active .menu-icon .svg-icon,
.app-sidebar .menu .menu-item .menu-link.active .menu-icon i {
  color: var(--kt-primary-inverse) !important;
}

.app-sidebar .menu .menu-item .menu-link.active .menu-title {
  color: #000000;
  font-weight: bold;
}


.app-sidebar-toggle {
  background-color: var(--kt-primary) !important;
}

.link-primary,
.link-primary:hover,
.link-primary:focus {
  color: var(--kt-primary) !important;
}
.swal2-styled.swal2-confirm,
.swal2-styled.swal2-confirm:hover {
  background-color: var(--kt-primary) !important;
}
.swal2-styled.swal2-confirm:focus {
  box-shadow: 0 0 0 2px rgb(117 47 139 / 50%) !important;
}
.swal2-styled.swal2-cancel,
.swal2-styled.swal2-cancel:hover {
  background-color: var(--kt-secondary) !important;
}
button.swal2-styled {
  transition: 0.3s ease all;
}
button.swal2-styled:hover {
  filter: saturate(0.6);
}
.btn-check:checked + .btn.btn-outline.btn-outline-primary,
.btn-check:active + .btn.btn-outline.btn-outline-primary,
.btn.btn-outline.btn-outline-primary:focus:not(.btn-active),
.btn.btn-outline.btn-outline-primary:hover:not(.btn-active),
.btn.btn-outline.btn-outline-primary:active:not(.btn-active),
.btn.btn-outline.btn-outline-primary.active,
.btn.btn-outline.btn-outline-primary.show,
.show > .btn.btn-outline.btn-outline-primary {
  color: var(--kt-primary-inverse);
  border-color: var(--kt-primary);
  background-color: var(--kt-primary-active) !important;
}
.scrolltop {
  background-color: var(--kt-secondary) !important;
}
.page-link:hover {
  z-index: 2;
  color: #ffffff !important;
  background-color: #49494e !important;
}
.app-footer {
  display: none !important;
}
.page-item .page-link {
  height: 1.5rem;
  min-width: 1.5rem;
  font-size: 0.9rem;
  padding: 0;
  line-height: normal;
  transition: 0.3s ease all;
  border: 1px solid #eaeaea;
  border-radius: 0;
}
.page-item .page-link .previous,
.page-item .page-link .next {
  display: block;
  height: 0.7rem;
  width: 0.7rem;
}
.page-item .page-link i {
  font-size: 0.7rem;
}
.page-item .page-link .previous,
.page-item .page-link .next {
  background-color: #49494e !important;
}
.page-item .page-link:hover .next,
.page-item .page-link:hover .previos,
.page-item:hover:not(.active):not(.offset):not(.disabled) .page-link .previous {
  background-color: #ffffff !important;
}
.page-item {
  margin-right: -1px;
}

@media screen and (min-width: 991px) {
  .login-side {
    display: block !important;
    background-color: var(--kt-primary);
    position: relative;
  }

  .caMainLogo {
    width: 12rem;
  }

  .login-side img {
    display: block;
    opacity: 0.05;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 50%;
  }

  .login-side::after {
    content: '';
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
    background-repeat: no-repeat;
    background-image: url();
  }
}

.separator {
  background-color: #f2f8fa;
  border-top-width: 1px;
  border-top-color: #f2f8fa;
}

.kt-primary-text {
  color: var(--kt-primary);
}

.dropdown-item.active,
.dropdown-item:active {
  color: white !important;
  text-decoration: none;
  background-color: #49494d !important;
}

.custom-select-page {
  font-size: 13px !important;
  padding: 0px 26px 0px 17px !important;
  background-color: #49494d !important;
  color: white !important;
}

.customTrGroups {
  border-bottom: solid 2px #f0f2f5 !important;
}
.centered-cell {
  width: 14ch;
  text-align: center;
}


.split-button .btn {
  flex: initial !important;
}

.custom-rounded-card {
  border-radius: 30px !important;
}
.custom-select {
  color: white;
  background-color: var(--kt-primary) !important;
  border-color:  var(--kt-primary) !important;
}

.custom-select option {
  color: black;
  background-color: white;
}


.custom-color-card {
  background-color: var(--kt-primary) !important;; /* Reemplaza "#ff0000" con tu color personalizado */
  /* Otros estilos personalizados */
}

.dropdown-item-custom {
  background-color: transparent;
  color: inherit;
  text-decoration: none;
}

.dropdown-item-custom:hover {
  background-color: transparent;
  color: inherit;
}


//Para siderbar en el menu


.app-sidebar .menu .menu-item .menu-link.active {
  background-color: #F2F3F4 !important;
  border-left: 2px solid var(--kt-primary) !important;
  /* Cambia "red" al color deseado para la línea en el margen izquierdo */
  border-radius: 0;
  /* Elimina el border-radius */
}


.text-primary {
  color: var(--kt-primary) !important;
}



.my-svg-icon {
  fill: var(--kt-primary) !important;; /* Cambia esto al color que desees */
}


.custom-cell {
  text-align: right;
  padding-right: 4rem !important;
}
@media (max-width: 767px) {
  .custom-gap {
    margin-right: 2rem;
  }
}

@media (min-width: 768px) {
  .custom-gap {
    margin-right: 2 rem;
  }
}


@media (max-width: 767px) {
  .table-container {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .table-container {
    width: 80%;
  }
}


.apexcharts-legend.apx-legend-position-right, .apexcharts-legend.apx-legend-position-left {
  flex-direction: row !important;
  bottom: 0;
}

.apexcharts-legend-marker {
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin-right: 3px;
  margin-left: 15px;
  border-style: solid;
}


.custom-control-input:checked ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff;
}


.my-custom-cardIndicador {
  width: 8.7rem !important;
  height: 8.5rem !important;
  border: 2px dashed #ebebeb !important;
  border-radius: 5px !important;
  height: 5.5rem!important;
  width: 13rem!important;
}
.my-custom-cardIndicador .card-body{
  padding: 0px !important;
}


.circular-button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
ul.ks-cboxtags {
  list-style: none;
  padding: 20px;
}
ul.ks-cboxtags li{
display: inline;
}
ul.ks-cboxtags li label{
  display: inline-block;
  background-color: rgba(255, 255, 255, .9);
  border: 2px solid rgba(139, 139, 139, .3);
  color: #adadad;
  border-radius: 25px;
  white-space: nowrap;
  margin: 3px 0px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  transition: all .2s;
}

ul.ks-cboxtags li label {
  padding: 8px 12px;
  cursor: pointer;
}

ul.ks-cboxtags li label::before {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 12px;
  padding: 2px 6px 2px 2px;
  content: "\f067";
  transition: transform .3s ease-in-out;
}

ul.ks-cboxtags li input[type="checkbox"]:checked + label::before {
  content: "\f00c";
  transform: rotate(-360deg);
  transition: transform .3s ease-in-out;
}

ul.ks-cboxtags li input[type="checkbox"]:checked + label {
  border: 2px solid var(--kt-primary) !important;
  background-color: var(--kt-primary) !important;
  color: #fff;
  transition: all .2s;
}

ul.ks-cboxtags li input[type="checkbox"] {
display: absolute;
}
ul.ks-cboxtags li input[type="checkbox"] {
position: absolute;
opacity: 0;
}
ul.ks-cboxtags li input[type="checkbox"]:focus + label {
border: 2px solid #e9a1ff;
}